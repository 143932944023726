/**
 * Generic log in form utilities. Enables the following behaviour:
 * - Email autocomplete
 * - Email typo detection and suggestion
 * - Reveal password
 * - Displays a warning when CAPSLOCK is enabled.
 *
 * @author Matt Hallett
 */
'use strict';

const buildIcon = require('Scripts/common/build-icon');
const EmailGenie = require('Vendor/email-genie');
const emailSpellChecker = require('Vendor/@zootools/email-spell-checker');

module.exports = FormUtils;

function FormUtils() {
	this.init = function() {
		this.ui = {
			emailField:     $('.js-email-field'),
			nameField:      $('.js-name-field'),
			passwordField:  $('.js-password-field'),
			passwordToggle: $('.js-password-toggle'),
		};

		// Disable caps lock warning on IE and Edge as we use our own
		document.msCapsLockWarningOff = true;
		
		this.bindEventHandlers();

		// Autocomplete email addresses with "gmail.com etc"
		this.ui.emailField.each((i,field) => {
			// EmailGenie default domains: ['gmail.com', 'outlook.com', 'hotmail.com', 'msn.com', 'live.com', 'googlemail.com', 'yahoo.com', 'me.com', 'icloud.com']
			// Add some custom ones as among our most popular
			var domains = ['btinternet.com', 'live.co.uk', 'hotmail.co.uk', 'yahoo.co.uk', 'aol.com', 'sky.com'];

			// Use data-domain="xxx" to pass in extra custom domain
			var domain = $(field).data('domain');
			if(domain) domains.push(domain);

			new EmailGenie(field, {	
				domains: domains
			});
		})
	};

	this.bindEventHandlers = function() {
		this.ui.passwordField.on('keydown keyup', this.capslock.bind(this));
		this.ui.passwordToggle.on('click', this.togglePassword.bind(this));
		this.ui.emailField.on('blur', this.onBlurEmail.bind(this));
	};

	this.capslock = function(e) {
		var capsMsg = $(".js-caps-warning");

		if (e.originalEvent.getModifierState && 
			e.originalEvent.getModifierState("CapsLock")) {
			capsMsg.show();
		} else {
			capsMsg.hide();
		}
	}

	// Allow user to view their own password
	this.togglePassword = function(e) {
		var passwords = this.ui.passwordField;
		var password = $(e.currentTarget).siblings('.js-password-field') || passwords;
		
		if (passwords.attr('type') === "password") {
			passwords.attr('type', "text");
			this.ui.passwordToggle.html("Hide");
			password.focus();
		} else {
			passwords.attr('type', "password");
			this.ui.passwordToggle.html("Show");
		}
	}

	// Spell-check email and suggest alternative using emailSpellChecker
	this.onBlurEmail = function(e) {
		const emailField = $(e.currentTarget);

		const suggestion = emailSpellChecker.run({
			email: emailField.val(),
			topLevelDomains: emailSpellChecker.POPULAR_TLDS.concat('org.uk'),
		});

		let alertElement = emailField.siblings(".js-email-suggestion");
		const hasExistingAlert = alertElement.length;

		if(suggestion) {
			if(!hasExistingAlert) {
				alertElement = $(document.createElement("div"));
				alertElement.addClass("mt-2 js-email-suggestion");
				alertElement.attr("role", "alert");
			}

			alertElement.html(`${buildIcon('fa-circle-question')} <a href="javascript:void(0)" class="link-block-teal">Did you mean ${suggestion.full}?</a>`);

			if(!hasExistingAlert) {
				alertElement.insertAfter(emailField);
			}

			alertElement.find("a").off().on("click", () => {
				emailField.val(suggestion.full);
				alertElement.remove();
			});
		} else {
			alertElement.remove();
		}
	}

	this.init();
}